.navbarbutton{
    padding: 0.5em 1.4em;
 display: flex;
 align-items: center;
 justify-content: center;
//  height: 2.5rem;
//  width: 8rem;
 border: 2px solid #ffffff;
 position: relative;
 overflow: hidden;
 background-color: transparent;
 text-align: center;
 text-transform: uppercase;
 font-size: 16px;
 transition: .3s;
 z-index: 1;
 font-family: inherit;
 color: #ffffff;
}

.navbarbutton::before {
 content: '';
 width: 0;
 height: 300%;
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%) rotate(45deg);
 background: #fff;
 transition: .5s ease;
 display: block;
 z-index: -1;
}

.navbarbutton:hover::before {
 width: 105%;
}

.navbarbutton:hover {
 color: black;
}
img{
    width: 10rem;
}
.box{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: #212e56;
}

.card {
    position: relative;
    background-color: #f2f2f2;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    perspective: 1000px;
    box-shadow: 0 0 0 5px #ffffff80;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .card svg {
    width: 48px;
    fill: #333;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
  }
  
  .card__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f2f2f2;
    transform: rotateX(-90deg);
    transform-origin: bottom;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .card:hover .card__content {
    transform: rotateX(0deg);
  }
  
  .card__title {
    margin: 0;
    font-size: 24px;
    color: #333;
    font-weight: 700;
  }
  
  .card:hover svg {
    scale: 0;
  }
  
  .card__description {
    margin: 10px 0 0;
    font-size: 14px;
    color: #777;
    line-height: 1.4;
  }
  
  
  .buttonn {
    font-family: monospace;
    font-size: 1.5rem;
    color: #FAFAFA;
    text-transform: uppercase;
    border: 2px solid #FAFAFA;
    background: #252525;
    box-shadow: 3px 3px #fafafa;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .buttonn:hover {
    box-shadow: none;
    transform: translate(3px, 3px);
  }



  .radio-input input {
    display: none;
  }
  
  .radio-input {
    --container_width: 700px;
    position: relative;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: black;
    width: var(--container_width);
    overflow: hidden;
    text-align: center;
  }
  .radio-input :hover{
    font-size: 110%;
  }
  
  .radio-input label {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    font-weight: 600;
  }
  
  .selection {
    display: none;
    position: absolute;
    height: 100%;
    width: calc(var(--container_width) / 3);
    z-index: 0;
    left: 0;
    top: 0;
    transition: .15s ease;
  }
  
  .radio-input label:has(input:checked) {
    color: #415CAB;
  }
  
  .radio-input label:has(input:checked) ~ .selection {
    border-bottom: 2px solid #090F1D;
    display: inline-block;
  }
  
  .radio-input label:nth-child(1):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 0/3));
  }
  
  .radio-input label:nth-child(2):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 1/3));
  }
  
  .radio-input label:nth-child(3):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 2/3));
  }



  // toast box


  #toastBox{
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    overflow: hidden;
    padding: 20px;
  }


  //button 3

  .button3 {
    align-items: center;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    text-decoration: none;
    text-transform: uppercase;
    outline: 0;
    border: 0;
    padding: 1rem;
  }
  
  .button3:before {
    background-color: #fff;
    content: "";
    display: inline-block;
    height: 1px;
    margin-right: 10px;
    transition: all .42s cubic-bezier(.25,.8,.25,1);
    width: 0;
  }
  
  .button3:hover:before {
    background-color: #fff;
    width: 3rem;
  }

  // catalogue cards

  .cataloguecard p {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    color: #212e56;
  }
  
  .cataloguecard p.small {
    font-size: 14px;
  }
  
  .go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #2e4078;
    border-radius: 0 4px 0 32px;
  }
  
  .go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
  }
  
  .card1 {
    display: block;
    position: relative;
    background-color: #B4CDED;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
  }
  
  .card1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #2e4078;
    height: 72px;
    width: 72px;
    border-radius: 20px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }
  
  .card1:hover:before {
    transform: scale(61);
  }
  
  .card1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 1);
  }
  
  .card1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
  }
  
  .card2 {
    display: block;
    top: 0px;
    position: relative;
    max-width: 262px;
    background-color: #f2f8f9;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    border: 1px solid #f2f8f9;
  }
  
  .card2:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #ccc;
    background-color: white;
  }
  
  .card2:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #00838d;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }
  
  .card2:hover:before {
    transform: scale(2.15);
  }
  
  .card3 {
    display: block;
    top: 0px;
    position: relative;
    max-width: 262px;
    background-color: #f2f8f9;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    overflow: hidden;
    border: 1px solid #f2f8f9;
  }
  
  .card3 .go-corner {
    opacity: 0.7;
  }
  
  .card3:hover {
    border: 1px solid #415cab;
    box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);
    z-index: 500;
  }
  
  .card3:hover p {
    color: #00838d;
  }
  
  .card3:hover .go-corner {
    transition: opactiy 0.3s linear;
    opacity: 1;
  }
  
  .card4 {
    display: block;
    top: 0px;
    position: relative;
    max-width: 262px;
    background-color: #fff;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    overflow: hidden;
    border: 1px solid #ccc;
  }
  
  .card4 .go-corner {
    background-color: #415cab;
    height: 100%;
    width: 16px;
    padding-right: 9px;
    border-radius: 0;
    transform: skew(6deg);
    margin-right: -36px;
    align-items: start;
    background-image: linear-gradient(-45deg, #8f479a 1%, #dc2a74 100%);
  }
  
  .card4 .go-arrow {
    transform: skew(-6deg);
    margin-left: -2px;
    margin-top: 9px;
    opacity: 0;
  }
  
  .card4:hover {
    border: 1px solid #cd3d73;
  }
  
  .card4 h3 {
    margin-top: 8px;
  }
  
  .card4:hover .go-corner {
    margin-right: -12px;
  }
  
  .card4:hover .go-arrow {
    opacity: 1;
  }