.catalogue{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #FFFFFF;
    background: url('../Assets/photos/shridhar-thorat-6AKLKt-KmdY-unsplash.jpg') center/cover no-repeat;
}
.btn-catalogue{
  padding-top: 2vh;
}
.button-19 {
    appearance: button;
    background-color: #162b6a;
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: din-round,sans-serif;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: .8px;
    line-height: 20px;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 13px 50px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: filter .2s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
  }
  
  .button-19:after {
    background-clip: padding-box;
    background-color: #415cab;
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    bottom: -4px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  
  .button-19:main,
  .button-19:focus {
    user-select: auto;
  }
  
  .button-19:hover:not(:disabled) {
    filter: brightness(1.1);
    -webkit-filter: brightness(1.1);
  }
  
  .button-19:disabled {
    cursor: auto;
  }


@keyframes visibleincoming{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.foranimation{
    animation-name: visibleincoming;
    animation-duration: 2s;
}
