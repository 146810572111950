  @import url('https://fonts.googleapis.com/css2?family=Cabin:display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Cabin:display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@500&family=Hind+Siliguri&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Signika+Negative&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Arvo:wght@700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Mitr&display=swap');
  
  @font-face {
    font-family: "Dhurjati-Regular";
    src: url('./Assets/fonts/Dhurjati-Regular.ttf') format('ttf');
}
.font-dhurjati {
    font-family: 'Cabin', sans-serif;
}
.font-cabin {
    font-family: 'Cabin', sans-serif;
}
.font-hind {
    font-family: 'Exo 2', sans-serif;
}
.font-Signika {
    font-family: 'Signika Negative', sans-serif;
}
.font-Arvo {
    font-family: 'Mitr', sans-serif;
    letter-spacing: 1px;
}